<script>
import { Money } from "v-money";

export default {
  props: {
    inputID: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: "",
    },
    precision: {
      default: 2,
      type: Number,
    },
    value: {
      default: 0,
      type: Number,
    },
  },
  data() {
    return {
      price: this.value,
      money: {
        // suffix: "",
        prefix: "US $",
        thousands: ",",
        decimal: ".",
        precision: this.precision,
        masked: false,
      },
    };
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        // console.log(value);
        this.$emit("input", value);
      },
    },
  },
  components: {
    Money,
  },
};
</script>

<template>
  <b-field :label="label" :label-for="inputID">
    <money v-model="model" v-bind="money" class="input" :id="inputID" />
  </b-field>
</template>
